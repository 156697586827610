import axios from 'axios';
import { Core as YubinBangoCore } from 'yubinbango-core';

document.addEventListener('DOMContentLoaded', () => {
	'use strict';

	console.log('WP Stripe Cart is loaded !');

	//Get Browser lang
	const browser_lang = navigator.language || navigator.userLanguage;
	console.log(browser_lang);

	//Set STRIPE style, Prefecture and Country variables
	let state = {};
	let countryList = {};
	let style = {};
	let shippingDefault;
	let stripeCurrency;
	let stripeTotalAmount = 0;
	let paymentRequest;
	let paymentRequestButton;
	// Global variable to store the submit button text.
	let submitButtonPayText = 'Pay';

	//Variable to store cart item 
	let cartItem = {};

	//Variable to store form label
	let form_label = {};

	//Variable to store settings
	let setting = {};

	//Variable to store subtotal,tax and total
	let cartCalculated = {};

	//variable to store sum of Cart Item quantity
	let cartItemSum = 0;

	//Constanats
	const product_container = '.wp-stripe-cart-product';
	const product_image_class = '.wp-stripe-cart-js-image';
	const product_name_class = '.wp-stripe-cart-product__name';
	const product_price_class = '.wp-stripe-cart-product__price';
	const product_quantity_class = '.wp-stripe-cart-js-quantity';

	//notice
	const notice_container = document.querySelector('.wsc-cart-notice');
	const notice_image = document.querySelector('.wsc-cart-notice-image');
	const notice_close_button = document.querySelector('.wsc-close-notice');

	//cart
	const cart_bubble = document.querySelectorAll('.wsc-cart-bubble');

	const cart_item_template = document.getElementById('wsc-cart-item-template');
	const cart_item_image_class = 'wsc-cart-item-image';
	const cart_item_name_class = 'wsc-cart-item-name';
	const cart_item_price_class = 'wsc-cart-item-price';
	const cart_quantity_class = 'wsc-quantity-input';
	const cart_update_class = 'wsc-cart-update';
	const cart_remove_class = 'wsc-remove-item';

	const cart_subtotal = document.querySelectorAll('.wsc-subtotal-amount');
	const cart_tax = document.querySelectorAll('.wsc-tax-amount');
	const cart_shipping = document.querySelectorAll('.wsc-shipping-amount');
	const cart_total = document.querySelectorAll('.wsc-total-amount');
	
	const cart_empty_msg = document.querySelector('.wp-stripe-cart-js-empty');
	const cart_item_container = document.querySelector('.wsc-cart-items');
	const cart_item_class = 'wsc-cart-item';
	const cart_add_button = document.querySelectorAll('.wsc-add-to-cart');
	const cart_open_button = document.querySelectorAll('.wsc-open-cart');
	const cart_close_button = document.querySelector('.wsc-cart-close');

	//Checkout Constants
	const state_holder = document.querySelector('.wsc-load-state');
	const country_container = document.querySelector('.wsc-set-country');
	const cart_fieldset = document.querySelector('.wsc-cart-fieldset');

	const form_label_country = document.querySelector('.wsc-label-country');
	const form_label_name = document.querySelector('.wsc-label-name');
	const form_label_email = document.querySelector('.wsc-label-email');
	const form_label_city = document.querySelector('.wsc-label-city');
	const form_label_state = document.querySelector('.wsc-label-state');
	const form_label_zip = document.querySelector('.wsc-label-zip');
	const form_label_line1 = document.querySelector('.wsc-label-line1');
	const form_label_line2 = document.querySelector('.wsc-label-line2');

	const form_input_name = 'input[name=name]';
	const form_input_email = 'input[name=email]';
	const form_input_line1 = 'input[name=address1]';
	const form_input_line2 = 'input[name=address2]';
	const form_input_city = 'input[name=city]';
	const form_input_state = '[name=state]';
	const form_input_zip = 'input[name=postal_code]';
	const form_input_country = '[name=country]';

	let state_input_html;

	//MARK: Stripe Constants
	//STRIPE
	const wp_stripe_cart_container = document.querySelector('.wp-stripe-cart-cart');
	const wp_stripe_checkout_loader = document.querySelector('.wp-stripe-cart-loaderbox');
	const wp_stripe_checkout_container = document.querySelector('.wp-stripe-cart-checkout');
	const wp_stripe_back_to_cart = document.querySelector('.wp-stripe-cart-checkout__back');
	const wp_stripe_checkout_button = document.querySelector('.wsc-stripe-checkout');
	const wp_stripe_checkout_done = document.querySelector('.wp-stripe-cart-checkout__done');
	const confirmationElement = document.getElementById('confirmation');
	const form = document.getElementById('wp-stripe-cart-payment-form');
	const submitButton = form.querySelector('button.wp-stripe-cart-payment-button[type=submit]');

	//Disable checkout button
	wp_stripe_checkout_button.setAttribute('disabled',true);

	//Store shipping default value
	shippingDefault = cart_shipping[0].innerHTML;

	(async () => {

		//Set WordPress ajax request for Stripe Style
		const wp_ajax_load_settings = new URLSearchParams();
				
		//set WordPress ajax action
		wp_ajax_load_settings.append('action', 'load_settings');

		if(!Object.keys(setting).length){
			await axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_load_settings
			}).then(res => {
				setting = res.data;
			}).catch(error => {
				const {
				status,
				statusText
				} = error.response;
				console.log(`Error! HTTP Status: ${status} ${statusText}`);
				setting = {};
			});
		}

		//Set WordPress ajax request for Stripe Style
		const wp_ajax_load_form_label = new URLSearchParams();
				
		//set WordPress ajax action
		wp_ajax_load_form_label.append('action', 'load_form_label');

		if(!Object.keys(form_label).length){
			await axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_load_form_label
			}).then(res => {
				form_label = res.data[browser_lang] ? res.data[browser_lang] : res.data['en'];

				form_label_country.innerText = form_label.country;
				form_label_name.innerText = form_label.name;
				form_label_email.innerText = form_label.email;
				form_label_city.innerText = form_label.city;
				form_label_state.innerText = form_label.state;
				form_label_zip.innerText = form_label.zip;
				form_label_line1.innerText = form_label.line1;
				form_label_line2.innerText = form_label.line2;

				state_input_html = state_holder.innerHTML;
			}).catch(error => {
				const {
				status,
				statusText
				} = error.response;
				console.log(`Error! HTTP Status: ${status} ${statusText}`);
				form_label = {};
			});
		}

		//Set WordPress ajax request for Stripe Style
		const wp_ajax_restore_cart = new URLSearchParams();
					
		//set WordPress ajax action
		wp_ajax_restore_cart.append('action', 'restore_cart');

		await axios({
			method: 'post',
			url: ajaxurl,
			data: wp_ajax_restore_cart
		}).then(res => {
			let cartData = res.data;
			cartItem = cartData.cartItem;
			cartCalculated = cartData.calculated;
			add_to_cart(cartData.cartItem,cartData.calculated);
			update_cart_bubble(cartData.cartItem);
		}).catch(error => {
			const {
			status,
			statusText
			} = error.response;
			console.log(`Error! HTTP Status: ${status} ${statusText}`);
			let cartData = {
				'cartItem': [],
				'calculated':{
					'currency' : 'JPY',
					'subtotal' : 0,
					'tax' : 0,
					'total' : 0
				}
			};
		});
	})();

	//bind event for dynamically created elements
	document.addEventListener('click',function(event){
		//Cart item remove
		if(event.target && event.target.classList.contains(cart_remove_class)){
			let product_id = event.target.closest(`.${cart_item_class}`).id;
			delete cartItem[product_id];
			update_cart(cartItem);
			update_cart_bubble(cartItem);
			event.target.closest(`.${cart_item_class}`).classList.add('deleted');
			
			let transition = transition_event();
			transition && event.target.closest(`.${cart_item_class}`).addEventListener(transition, () => {
				event.target.closest(`.${cart_item_class}`).remove();
				if(Object.keys(cartItem).length == 0){
					cart_empty_msg.classList.remove('not_empty');
					wp_stripe_checkout_button.setAttribute('disabled',true);
				}
			});
		}
		//updated cart summary
		if(event.target && event.target.classList.contains(cart_update_class)){
			let product_id = event.target.closest(`.${cart_item_class}`).id;
			let updated_quantity = event.target.parentNode.querySelector(`.${cart_quantity_class}`).value;
			cartItem[product_id].quantity = updated_quantity;
			update_cart(cartItem);
			update_cart_bubble(cartItem);
			event.target.classList.remove('changed');
		}
	});

	document.addEventListener('change',function(event){
		//Display update after quantity changed
		if(event.target && event.target.classList.contains(cart_quantity_class)){
			if(event.target.type == 'number'){
				let current_value = event.target.defaultValue; 
				let min = event.target.min;
				let max = event.target.max;
				let updated_value = event.target.value;

				if(min && max){
					if(updated_value < min && updated_value > max){
						event.target.value = current_value;
					}
				}else if(min){
					if(updated_value < min){
						event.target.value = current_value;
					}
				}else if(max){
					if(updated_value > max){
						event.target.value = current_value;
					}
				}
				if(current_value != event.target.value){
					event.target.parentNode.querySelector(`.${cart_update_class}`).classList.add('changed');
					event.target.defaultValue = updated_value;
				}
			}
		}

		//set varied shipping fee after state changed.
		if(event.target && (event.target.name == 'state' || event.target.name == 'postal_code' )){
			submitButton.setAttribute('disabled',true);
			let selected_country = form.querySelector(form_input_country).value;
			let selected_state = form.querySelector(form_input_state).value;
			if(!setting.fixed_shipping_fee){
				const payment_description = setting.payment_description ? setting.payment_description : window.location.hostname;
				calculate_varied_shipping_fee(selected_country,selected_state).then( cartCalculated => {
					update_cart_summary(cartCalculated);
					submitButton.removeAttribute('disabled');

					paymentRequest.update({
						total: {
							label: payment_description,
							amount: stripeTotalAmount,
						}
					});
				});
			}
		}
	});


	notice_close_button.addEventListener('click', () => {
		notice_container.classList.remove('open');
		notice_image.innerHTML = '';
	});
	
	Array.from(cart_open_button).forEach((element) => {
		element.addEventListener('click', () => {
			wp_stripe_cart_container.classList.add('open');

			const cart_footer_height =  document.querySelector(".wp-stripe-cart-cart-footer").offsetHeight;
			const cart_header_height =  document.querySelector(".wp-stripe-cart-cart__header").offsetHeight;
			const viewport_height =  window.innerHeight;
			let cart_item_height =  viewport_height - (cart_footer_height + cart_header_height + 30);

			document.querySelector(".wp-stripe-cart-cart__ajax_products").style.height = cart_item_height;

			let viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

			if(viewport_width < 768){
				document.body.style.position = 'fixed';
				document.body.style.overflow = 'hidden';
				document.body.style.top = `-${window.scrollY}px`;
			}
		});
	});

	cart_close_button.addEventListener('click', () => {
		wp_stripe_cart_container.classList.remove('open');
		notice_container.classList.remove('open');

		let viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

		if(viewport_width < 768){
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.overflow = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	});

	wp_stripe_back_to_cart.addEventListener('click', () => {
		wp_stripe_checkout_container.classList.remove('open');
	});

	wp_stripe_checkout_done.addEventListener('click', (event) => {
		event.preventDefault();

		wp_stripe_cart_container.classList.remove('open');
		wp_stripe_cart_container.classList.remove('open');
		wp_stripe_checkout_container.classList.remove('success','error');
		wp_stripe_checkout_container.classList.remove('open');

		submitButton.disabled = false;
		submitButton.textContent = submitButtonPayText;
		document.stripe_payment_form.reset();

		let viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

		if(viewport_width < 768){
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.overflow = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	});

	(async () => {
		// Global variable to store the PaymentIntent object.
		let paymentIntent;
		
		// Create an instance of Elements.
		const elements = stripe.elements();

		// Create a Card Element and pass some custom styles to it.
		const card = elements.create('card', {style, hidePostalCode: true});
	
		// Mount the Card Element on the page.
		await card.mount('#card-element');

		// Monitor change events on the Card Element to display any errors.
		card.on('change', ({error}) => {
			const cardErrors = document.getElementById('card-errors');
			if (error) {
			  cardErrors.textContent = error.message;
			  cardErrors.classList.add('visible');
			} else {
			  cardErrors.classList.remove('visible');
			}
			// Re-enable the Pay button.
			submitButton.disabled = false;
		});

		/**
		 * Handle Payments...
		 */

		// Submit handler for our payment form.
		form.addEventListener('submit', async (event) => {
			event.preventDefault();

			// Disable the Pay button to prevent multiple click events.
			submitButton.disabled = true;
			submitButton.textContent = submitButton.dataset.processing;

			// Retrieve the user information from the form.
			const payment = form.querySelector('input[name=payment]:checked').value;
			const name = form.querySelector(form_input_name).value;
			const country = form.querySelector(`${form_input_country} option:checked`).value;
			const email = form.querySelector(form_input_email).value;

			const payment_description = setting.payment_description ? setting.payment_description : window.location.hostname;

			const billingAddress = {
				line1: form.querySelector(form_input_line1).value,
				line2: form.querySelector(form_input_line2).value,
				city: form.querySelector(form_input_city).value,
				postal_code: form.querySelector(form_input_zip).value,
				state: form.querySelector(form_input_state).value,
				country: country,
			};
			const shipping = {
				name,
				address: {
					line1: form.querySelector(form_input_line1).value,
					line2: form.querySelector(form_input_line2).value,
					city: form.querySelector(form_input_city).value,
					postal_code: form.querySelector(form_input_zip).value,
					state: form.querySelector(form_input_state).value,
					country: country,
				},
			};

			//MARK: PaymentIntent
			//Set WordPress ajax request for Prefecture
			const wp_ajax_create_payment_intent = new URLSearchParams();

			//set WordPress ajax action
			wp_ajax_create_payment_intent.append('action', 'create_payment_intent');
			//MARK: set amount, currency, payment_method_types for STRIPE
			wp_ajax_create_payment_intent.append('amount', stripeTotalAmount);
			wp_ajax_create_payment_intent.append('currency', stripeCurrency.toLowerCase());
			wp_ajax_create_payment_intent.append('receipt_email', email);
			wp_ajax_create_payment_intent.append('payment_method_types[]', 'card');
			wp_ajax_create_payment_intent.append('payment_description', payment_description);

			//Set paymentIntent.
			await axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_create_payment_intent
			}).then(res => {
				paymentIntent = res.data;
			}).catch(error => {
				const {
				status,
				statusText
				} = error.response;
				console.log(`Error! HTTP Status: ${status} ${statusText}`);
			});

			if (payment === 'card') {
				// Let Stripe.js handle the confirmation of the PaymentIntent with the card Element.
				try {
					const response = await stripe.confirmCardPayment(
					paymentIntent.client_secret,
					{
						payment_method: {
							card,
							billing_details: {
								name,
								email,
								address: billingAddress,
							},
						},
						shipping,
					});

					handlePayment(response);
				}catch(e){
					console.log(e);
					submitButton.disabled = false;
					submitButton.textContent = submitButtonPayText;
				}
			}
		});
		
		// Handle new PaymentIntent result
		const handlePayment = (paymentResponse) => {
	
			const {paymentIntent, error} = paymentResponse;
			
			if (error && error.type === 'validation_error') {
				submitButton.disabled = false;
				submitButton.textContent = submitButtonPayText;
			} else if (error) {
				confirmationElement.querySelector('.error-message').innerText = error.message;
				wp_stripe_checkout_container.classList.add('error');
			} else if (paymentIntent.status === 'succeeded') {
				wp_stripe_checkout_container.classList.add('success');
				card.clear();

				//Set WordPress ajax request for Prefecture
				const wp_ajax_save_order = new URLSearchParams();

				//set WordPress ajax action
				wp_ajax_save_order.append('action', 'save_order');
				wp_ajax_save_order.append('paymentIntent', JSON.stringify(paymentIntent));
				wp_ajax_save_order.append('order', JSON.stringify(cartItem));
				wp_ajax_save_order.append('order_summary', JSON.stringify(cartCalculated));

				clear_cart();

				//Save Order.
				axios({
					method: 'post',
					url: ajaxurl,
					data: wp_ajax_save_order
				}).then(res => {
					let post_status = res.data.status;
				}).catch(error => {
					const {
					status,
					statusText
					} = error.response;
					console.log(`Error! HTTP Status: ${status} ${statusText}`);
				});

			} else if (paymentIntent.status === 'requires_payment_method') {
				// Failure. Requires new PaymentMethod, show last payment error message.
				confirmationElement.querySelector('.error-message').innerText = paymentIntent.last_payment_error || 'Payment failed';
				wp_stripe_checkout_container.classList.add('error');
			} else {
				// Payment has failed.
				wp_stripe_checkout_container.classList.remove('success');
				wp_stripe_checkout_container.classList.add('error');
			}
		};

		wp_stripe_checkout_button.addEventListener('click', () => {
			wp_stripe_checkout_loader.classList.add('loading');
	
			let viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

			if(viewport_width < 768){
				document.body.style.position = 'fixed';
				document.body.style.overflow = 'hidden';
				document.body.style.top = `-${window.scrollY}px`;
			}
	
			(async () => {
		
				const updateSubmitButtonPayText = (newText) => {
					submitButton.textContent = newText;
					submitButtonPayText = newText;
				};
	
				//Set WordPress ajax request for Stripe Style
				const wp_ajax_load_stripe_style = new URLSearchParams();
		
				//set WordPress ajax action
				wp_ajax_load_stripe_style.append('action', 'load_stripe_style');
				
				if(!Object.keys(style).length){
					await axios({
						method: 'post',
						url: ajaxurl,
						data: wp_ajax_load_stripe_style
					}).then(res => {
						style = res.data;
					}).catch(error => {
						const {
						status,
						statusText
						} = error.response;
						console.log(`Error! HTTP Status: ${status} ${statusText}`);
						style = {};
					});
				}

				if(setting.default_country == 'JP'){
					let postalCodeInput = document.getElementsByName('postal_code');
					postalCodeInput[0].addEventListener('keyup',(event) => {
						let postalCode = event.target.value;
						if (postalCode.length >= 7) {
							new YubinBangoCore(postalCode, (addr)=> {
								document.getElementsByName('state')[0].value = addr.region;
								document.getElementsByName('city')[0].value = addr.locality;
								document.getElementsByName('address1')[0].value = addr.street;
								document.getElementsByName('address2')[0].value = addr.extended;
								document.getElementsByName('address1')[0].focus();
							});
						}else{
							document.getElementsByName('state')[0].value = "";
							document.getElementsByName('city')[0].value = "";
							document.getElementsByName('address1')[0].value = "";
							document.getElementsByName('address2')[0].value = "";
						}
					});
				}

				//load initial state of default country
				load_state(setting.default_country).then(state_data => {
					state = state_data;
					set_state(state);
					console.log(setting.default_country);
					showRelevantFormFields(setting.default_country);
				});
	
				//Set WordPress ajax request for Country
				const wp_ajax_load_country = new URLSearchParams();
		
				//set WordPress ajax action
				wp_ajax_load_country.append('action', 'load_country');
				wp_ajax_load_country.append('lang', browser_lang);
		
		
				//Set Country field.
				if(!Object.keys(countryList).length){
					await axios({
						method: 'post',
						url: ajaxurl,
						data: wp_ajax_load_country
					}).then(res => {
						countryList = res.data;
					}).catch(error => {
						const {
						status,
						statusText
						} = error.response;
						console.log(`Error! HTTP Status: ${status} ${statusText}`);
						countryList = {};
					});
				}
		
				//MARK: set country
				// Listen to changes to the user-selected country.
				country_container.addEventListener('change', (event) => {
					event.preventDefault();
					document.querySelector('.wp-stripe-cart-loaderbox').classList.add('loading');
					let selected_country = event.target.value;
					if(selected_country == 'JP'){
						let postalCodeInput = document.getElementsByName('postal_code');
						postalCodeInput[0].addEventListener('keyup',(event) => {
							let postalCode = event.target.value;
							if (postalCode.length >= 7) {
								new YubinBangoCore(postalCode, (addr)=> {
									document.getElementsByName('state')[0].value = addr.region;
									document.getElementsByName('city')[0].value = addr.locality;
									document.getElementsByName('address1')[0].value = addr.street;
									document.getElementsByName('address2')[0].value = addr.extended;
									document.getElementsByName('address1')[0].focus();
								});
							}else{
								document.getElementsByName('state')[0].value = "";
								document.getElementsByName('city')[0].value = "";
								document.getElementsByName('address1')[0].value = "";
								document.getElementsByName('address2')[0].value = "";
							}
						});
					}

					set_country(selected_country);
					load_state(selected_country).then(state_data => {
						state = state_data;
						set_state(state);
						showRelevantFormFields(selected_country);
						if(!setting.fixed_shipping_fee){
							//MARK: 送料計算ここに
							calculate_varied_shipping_fee(selected_country).then( cartCalculated => {
								update_cart_summary(cartCalculated);
								document.querySelector('.wp-stripe-cart-loaderbox').classList.remove('loading');
							});
						}
					});
				});
		
				//init country
				if(countryList){
					let countryOptions = '';
					if(setting.filter_countries.length){
						countryList.map(data => { if( setting.filter_countries.includes(data.value)) { countryOptions += `<option value="${data.value}">${data.label}</option>` }})
					}else{
						countryList.map(data => countryOptions += `<option value="${data.value}">${data.label}</option>`)
					}
					if(setting.filter_countries.length == 1) country_container.closest('label').style.display ="none";
					country_container.innerHTML = countryOptions;
					set_country(setting.default_country);
				}
	
				if(state && style && countryList){
					showRelevantFormFields(setting.default_country);
					wp_stripe_checkout_container.classList.add('open');
					document.querySelector('.wp-stripe-cart-loaderbox').classList.remove('loading');
				}
	
				//MARK: Apple Pay callback?
				// Callback when a payment method is created.
				paymentRequest.on('paymentmethod', async (event) => {
					// Confirm the PaymentIntent with the payment method returned from the payment request.

					const name = form.querySelector(form_input_name).value;
					const email = form.querySelector(form_input_email).value;
					const country = form.querySelector(`${form_input_country} option:checked`).value;

					const payment_description = setting.payment_description ? setting.payment_description : window.location.hostname;

					//MARK: PaymentIntent
					//Set WordPress ajax request for Prefecture
					const wp_ajax_create_payment_intent = new URLSearchParams();

					//set WordPress ajax action
					wp_ajax_create_payment_intent.append('action', 'create_payment_intent');
					//MARK: set amount, currency, payment_method_types for STRIPE
					wp_ajax_create_payment_intent.append('amount', stripeTotalAmount);
					wp_ajax_create_payment_intent.append('currency', stripeCurrency.toLowerCase());
					wp_ajax_create_payment_intent.append('receipt_email', email);
					wp_ajax_create_payment_intent.append('payment_description', payment_description);

					//Set paymentIntent.
					await axios({
						method: 'post',
						url: ajaxurl,
						data: wp_ajax_create_payment_intent
					}).then(res => {
						paymentIntent = res.data;
					}).catch(error => {
						const {
						status,
						statusText
						} = error.response;
						console.log(`Error! HTTP Status: ${status} ${statusText}`);
					});


					const response = await stripe.confirmCardPayment(
						paymentIntent.client_secret,
						{
							payment_method: event.paymentMethod.id,
							shipping: {
								name: name,
								phone: event.shippingAddress.phone,
								address: {
									line1: form.querySelector(form_input_line1).value,
									line2: form.querySelector(form_input_line2).value,
									city: form.querySelector(form_input_city).value,
									postal_code: form.querySelector(form_input_zip).value,
									state: form.querySelector(form_input_state).value,
									country: country,
								}
							},
						},
						{handleActions: false}
					);
					if (response.error) {
						// Report to the browser that the payment failed.
						event.complete('fail');
						handlePayment({error});
					} else {
						// Report to the browser that the confirmation was successful, prompting
						event.complete('success');
						handlePayment(response);
					}
				});
				
				// Create the Payment Request Button.
				if(!elements.getElement('paymentRequestButton')){
					paymentRequestButton = elements.create('paymentRequestButton', {
						paymentRequest,
					});
				}
				
				// Check if the Payment Request is available (or Apple Pay on the Web).
				const paymentRequestSupport = await paymentRequest.canMakePayment();
				if (paymentRequestSupport) {
					// Display the Pay button by mounting the Element in the DOM.
					paymentRequestButton.mount('#payment-request-button');
					// Show the payment request section.
					document.getElementById('payment-request').classList.add('visible');

					paymentRequestButton.on('click', function(event) {
						if (!form.reportValidity()) {
							event.preventDefault();
							return;
						}
					});
				}
	
			})();
	
		});
		
	})();

	if(cart_add_button){
		Array.from(cart_add_button).forEach((element) => {
			element.addEventListener('click', async (event) => {

				element.setAttribute('disabled',true);

				let product_id = event.target.getAttribute('data-product-id');
				let product_name = event.target.closest(product_container).querySelector(product_name_class).innerText;
				let product_image = (event.target.closest(product_container).querySelector(product_image_class)) ? event.target.closest(product_container).querySelector(product_image_class).getAttribute('src') : wsc_noimage;
				let product_price = event.target.closest(product_container).querySelector(product_price_class).getAttribute('data-amount');
				let product_currency = event.target.closest(product_container).querySelector(product_price_class).getAttribute('data-currency');
				let product_quantity = parseInt(event.target.closest(product_container).querySelector(product_quantity_class).value,10);

				if(Object.keys(cartItem).length == 0){
					cartItem = {};
				}
				if (typeof cartItem[product_id] === 'undefined') {
					cartItem[product_id] = {
						'name': product_name,
						'image': product_image,
						'price': product_price,
						'currency': product_currency,
						'quantity': parseInt(product_quantity,10)
					}
				}else{
					cartItem[product_id] = {
						'name': product_name,
						'image': product_image,
						'price': product_price,
						'currency': product_currency,
						'quantity': (parseInt(cartItem[product_id]['quantity'],10) + product_quantity)
					}
				}

				update_cart(cartItem).then(cartCalculated =>{
					add_to_cart(cartItem,cartCalculated);
					update_notice(product_image);
					update_cart_bubble(cartItem);
					element.removeAttribute('disabled');
				});
			});
		});
	}

	//Add to cart function
	const add_to_cart = (cartItems,cartCalculated) => {
		let cartHtml = '';
		Object.keys(cartItems).forEach((itemKey) => {
			let formatter = new Intl.NumberFormat('us-EN', {
				style: 'currency',
				currency: cartItems[itemKey].currency
			});

			set_currency(cartItems[itemKey].currency);

			let cartHtmlTemplate = cart_item_template.content.cloneNode(true).firstElementChild;
			cartHtmlTemplate.setAttribute('id',itemKey);
			cartHtmlTemplate.querySelector(`.${cart_item_image_class}`).src = cartItems[itemKey].image;
			cartHtmlTemplate.querySelector(`.${cart_item_name_class}`).innerText = cartItems[itemKey].name;
			cartHtmlTemplate.querySelector(`.${cart_item_price_class}`).innerText = formatter.format(cartItems[itemKey].price);
			cartHtmlTemplate.querySelector(`.${cart_quantity_class}`).setAttribute('value', cartItems[itemKey].quantity);

			cartHtml += cartHtmlTemplate.outerHTML;
		});

		if(Object.keys(cartItems).length){
			cart_empty_msg.classList.add('not_empty');
			wp_stripe_checkout_button.removeAttribute('disabled');
			cart_item_container.innerHTML = cartHtml;
			update_cart_summary(cartCalculated);
		}

	}

	const update_cart = async (cartItems) =>{

		//Set WordPress ajax request for Stripe Style
		const wp_ajax_update_cart_item = new URLSearchParams();
				
		//set WordPress ajax action
		wp_ajax_update_cart_item.append('action', 'calculate_cart_item');
		wp_ajax_update_cart_item.append('cart_item', JSON.stringify(cartItems));
		wp_ajax_update_cart_item.append('country', form_input_country.value);
		wp_ajax_update_cart_item.append('state', form_input_state.value);

		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_update_cart_item
			}).then(res => {
				cartCalculated = res.data;
				update_cart_summary(cartCalculated);
				resolve(res.data);
			}).catch(error => {
				const {
				status,
				statusText
				} = error.response;
				console.log(`Error! HTTP Status: ${status} ${statusText}`);
				cartCalculated = {
					'currency' : 'JPY',
					'subtotal' : 0,
					'tax' : 0,
					'total' : 0
				};
				reject(cartCalculated);
			});
		});
	};

	const update_cart_bubble = (cartItems) => {
		cartItemSum = 0;
		Object.keys(cartItems).map(itemKey => cartItemSum += parseInt(cartItems[itemKey].quantity,10));
		Array.from(cart_bubble).map(element => element.innerText = cartItemSum);
		if(cartItemSum > 0 ) Array.from(cart_bubble).map(element => element.classList.remove('empty'));
		else Array.from(cart_bubble).map(element => element.classList.add('empty'));
	};

	const update_cart_summary = (cartCalculated) => {
		let formatter = new Intl.NumberFormat('us-EN', {
			style: 'currency',
			currency: cartCalculated.currency
		});
		Array.from(cart_subtotal).map(element => element.innerText = formatter.format(cartCalculated.subtotal));
		Array.from(cart_tax).map(element => element.innerText = formatter.format(cartCalculated.tax));
		if(typeof cartCalculated.shipping != 'undefined') Array.from(cart_shipping).map(element => element.innerText = formatter.format(cartCalculated.shipping));
		Array.from(cart_total).map(element => element.innerText = formatter.format(cartCalculated.total));
		stripeTotalAmount = cartCalculated.total;
	};

	const update_notice = (productImage) => {
		let html = `<img src="${productImage}" alt="">`;
		notice_image.innerHTML = html;
		notice_container.classList.add('open');
	};

	const transition_event = () => {
		var t;
		var el = document.createElement('fakeelement');
		var transitions = {
		  'transition':'transitionend',
		  'OTransition':'oTransitionEnd',
		  'MozTransition':'transitionend',
		  'WebkitTransition':'webkitTransitionEnd'
		}
	
		for(t in transitions){
			if( el.style[t] !== undefined ){
				return transitions[t];
			}
		}
	};

	const set_currency = (currency) => {
		if(typeof stripeCurrency == 'undefined'){
			stripeCurrency = currency;
		}
	};

	const clear_cart = () => {
		cartItem = {};
		update_cart(cartItem);
		update_cart_bubble(cartItem);
		Array.from(cart_shipping).map(element => element.innerHTML = shippingDefault);
		cart_item_container.innerHTML = '';
		notice_container.classList.remove('open');
		notice_image.innerHTML = '';
		cart_empty_msg.classList.remove('not_empty');
		wp_stripe_checkout_button.setAttribute('disabled',true);
	};

	const load_state = (country) => {
		//Set WordPress ajax request for Prefecture
		const wp_ajax_load_state = new URLSearchParams();
		
		//set WordPress ajax action
		wp_ajax_load_state.append('action', 'load_state');
		wp_ajax_load_state.append('country', country);
		wp_ajax_load_state.append('lang', browser_lang);

		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_load_state
			}).then(res => {
				state = res.data;
				resolve(res.data);
			}).catch(error => {
				const {
					status,
					statusText
					} = error.response;
					console.log(`Error! HTTP Status: ${status} ${statusText}`);
					state = {};
				reject(state);
			});
		});
	};

	const set_state = (state_list) => {
		if(state_list){
			let stateElement = '';
			let stateOptions = '';

			state_list.data.map(state_item => stateOptions += `<option value="${state_item.value}">${state_item.label}</option>`);
			stateElement = `
				<span>${state_list.label}</span>
				<div id="state" class="field">
					<select class="wp-stripe-cart-state p-region" name="state" required>${stateOptions}</select>
				</div>
			`;

			state_holder.innerHTML = stateElement;
			state_holder.classList.add('select');
		}else{
			state_holder.innerHTML = state_input_html;
			state_holder.classList.remove('select');
		}
	};

	const set_country = (country) => {

		submitButton.setAttribute('disabled',true);

		country_container.querySelector(`option[value=${country}]`).selected = 'selected';
		country_container.closest('div').className = `field ${country.toLowerCase()}`;

		cart_fieldset.className = `wp-stripe-cart-fieldset wsc-cart-fieldset ${country.toLowerCase()}`;

		const payment_description = setting.payment_description ? setting.payment_description : window.location.hostname;

		//MARK: 送料計算ここに
		if(!setting.fixed_shipping_fee){
			calculate_varied_shipping_fee(country).then( cartCalculated => {
				update_cart_summary(cartCalculated);
				submitButton.removeAttribute('disabled');
				paymentRequest.update({
					total: {
						label: payment_description,
						amount: stripeTotalAmount,
					}
				});
			});
		}

		//MARK: PaymentRequest
		// Create the payment request.
		paymentRequest = stripe.paymentRequest({
			country: country,
			currency: stripeCurrency.toLowerCase(),
			total: {
				label: payment_description,
				amount: stripeTotalAmount,
			},
			requestPayerName: true,
			requestShipping: false,
			requestPayerEmail: true,
		});
	};

	// Show only form fields that are relevant to the selected country.
	const showRelevantFormFields = (country) => {
		if (!country) {
			country = country_container.querySelector('option:checked').value;
		}
		if(country == 'JP'){
			form.querySelector(form_input_name).tabIndex = 1;
			form.querySelector(form_input_email).tabIndex = 2;
			form.querySelector(form_input_zip).tabIndex = 3;
			form.querySelector(form_input_state).tabIndex = 4;
			form.querySelector(form_input_city).tabIndex = 5;
			form.querySelector(form_input_line1).tabIndex = 6;
			form.querySelector(form_input_line2).tabIndex = 7;
		}else{
			form.querySelector(form_input_name).tabIndex = 1;
			form.querySelector(form_input_email).tabIndex = 2;
			form.querySelector(form_input_zip).tabIndex = 7;
			form.querySelector(form_input_state).tabIndex = 6;
			form.querySelector(form_input_city).tabIndex = 5;
			form.querySelector(form_input_line1).tabIndex = 3;
			form.querySelector(form_input_line2).tabIndex = 4;
		}
	};

	// Get varied shipping fee depends on country or state
	const calculate_varied_shipping_fee = (country,state) => {

		//Set WordPress ajax request for Prefecture
		const wp_ajax_get_varied_shipping_fee = new URLSearchParams();
		
		//set WordPress ajax action
		wp_ajax_get_varied_shipping_fee.append('action', 'get_varied_shipping_fee');
		wp_ajax_get_varied_shipping_fee.append('country', country);
		if(state) wp_ajax_get_varied_shipping_fee.append('state', state);

		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxurl,
				data: wp_ajax_get_varied_shipping_fee
			}).then(res => {
				cartCalculated = res.data;
				resolve(cartCalculated);
			}).catch(error => {
				const {
					status,
					statusText
				} = error.response;
				console.log(`Error! HTTP Status: ${status} ${statusText}`);
				cartCalculated.shipping = 0;
				reject(cartCalculated);
			});
		});
	};
});